import {
  DataGrid,
  DataGridProps,
  GridSortItem,
  ruRU as gridRuRU,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { useMemo, useCallback, useState, ReactNode } from 'react'
import { useDebounce } from 'use-debounce'
import { BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { GridContext } from './context'
import { Filter, useFiltersQueryParams } from './Filters'
import { Footer } from './Footer'
import { GridCell } from './GridCell'
import { Header } from './Header'
import { MarkerLine, ResizableHeader } from './ResizableHeader'
import { DataGridWrapper } from './styled'
import {
  AsyncGridColumns,
  AsyncGridValidRowModel,
  Column,
  FetchRowsFn,
  FiltersApi,
} from './types'
import { useColumns } from './useColumns'
import { PAGE_QUERY, useMemoryQuery } from './useMemoryQuery'

export type AsyncGridProps<Row extends AsyncGridValidRowModel> = {
  gridKey: string
  nestedGridKey?: string
  columns: AsyncGridColumns<Row>
  fetchRows: FetchRowsFn<Row>
  filters?: Filter[]
  memoryLocal?: boolean
  externalFilter?: FiltersApi
  title?: ReactNode | string
  smallTitle?: ReactNode | string
  breadcrumbs?: BreadcrumbsList
  headerExtra?: ReactNode
  gridExtraTop?: ReactNode
  hideHeader?: boolean
} & Omit<DataGridProps<Row>, 'rows' | 'columns'>

export function AsyncGrid<Row extends AsyncGridValidRowModel>(
  props: AsyncGridProps<Row>,
) {
  const {
    gridKey,
    nestedGridKey,
    fetchRows,
    filters = [],
    columns: defaultColumns,
    memoryLocal,
    externalFilter,
    title,
    smallTitle,
    breadcrumbs,
    headerExtra,
    gridExtraTop,
    hideHeader,
    ...tableProps
  } = props

  const columnsParams = useColumns(gridKey, defaultColumns)

  /* Query params */
  const { page, setPage, pageSize, setPageSize } = useMemoryQuery(memoryLocal)

  const [filtersQueryParams] = useFiltersQueryParams(filters)
  const [sort, setSort] = useState<string | undefined>()

  const filtersQuery = useMemo(() => {
    return Object.entries(filtersQueryParams)
      .filter(([, filter]) => {
        if (typeof filter === 'object') return Boolean(filter?.id)
        return Boolean(filter)
      })
      .map(([attr, filter]) => ({
        key: attr,
        value: `${filter?.id ? filter.id : filter}`,
      }))
  }, [filtersQueryParams])

  const handlePageReset = () => {
    setPage(PAGE_QUERY)
  }

  const memoGridKey = useMemo(
    () => [
      gridKey,
      nestedGridKey,
      page,
      pageSize,
      filtersQueryParams,
      sort,
      externalFilter,
    ],
    [
      externalFilter,
      filtersQueryParams,
      gridKey,
      nestedGridKey,
      page,
      pageSize,
      sort,
    ],
  )
  const [debounceGridKey] = useDebounce(memoGridKey, 300)

  /* Fetch rows */
  const {
    isLoading,
    isFetching,
    isPreviousData,
    data: { rows, total } = { rows: [], total: 0 },
  } = useQuery(
    debounceGridKey,
    () =>
      fetchRows(
        page,
        pageSize,
        [...filtersQuery, ...(externalFilter || [])],
        sort,
      ),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    },
  )

  /* Callbacks */
  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page, 'replaceIn')
    },
    [setPage],
  )
  const handlePageSizeChange = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize, 'replaceIn')
    },
    [setPageSize],
  )

  const handleOnSort = useCallback((sortObjArray: GridSortItem[]) => {
    const sortObj = sortObjArray[0]
    // TODO: найти способ передачи кастомного ключа для фильтрации(который отличается от ключа колонки)
    const customField = sortObj?.field.match(/{(.*?)}/)?.[1]

    setSort(
      sortObj
        ? `${sortObj?.sort === 'asc' ? '-' : ''}${customField || sortObj.field}`
        : undefined,
    )
  }, [])

  const gridHeight = useMemo(() => {
    if (pageSize === 25) return '1162'
    if (pageSize === 50) return '2262'
    if (pageSize === 100) return '4462'

    return '502'
  }, [pageSize])

  return (
    <GridContext.Provider value={{ defaultColumns, gridKey, ...columnsParams }}>
      {!hideHeader && (
        <Header
          title={title}
          smallTitle={smallTitle}
          breadcrumbs={breadcrumbs}
          filters={filters}
          extra={headerExtra}
          onFiltersChange={handlePageReset}
        />
      )}
      {gridExtraTop}

      <DataGridWrapper
        sx={{
          height: `${gridHeight}px`,
        }}
      >
        <DataGrid
          columns={
            columnsParams.columns
              .filter(({ isActive }) => isActive ?? true)
              .map((column) => ({
                ...column,
                renderHeader: ResizableHeader,
              })) as Column[]
          }
          disableColumnMenu
          disableSelectionOnClick
          disableColumnSelector
          rows={rows}
          rowCount={total}
          loading={isLoading || (isFetching && isPreviousData)}
          onSortModelChange={handleOnSort}
          sortingMode='server'
          headerHeight={44}
          rowHeight={44}
          {...tableProps}
          components={{
            Cell: GridCell,
            ...tableProps.components,
          }}
          hideFooter
          localeText={gridRuRU.components.MuiDataGrid.defaultProps.localeText}
          columnBuffer={20}
        />
        <MarkerLine id='grid-marker-line' />
      </DataGridWrapper>

      <Footer
        total={total}
        pageSize={pageSize}
        page={page}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </GridContext.Provider>
  )
}

// Hack, чтобы использовать дженерики компонента после lazy лоадинга
export type TypedComponentType = typeof AsyncGrid
// Дефолтный экспорт для React.lazy
export default AsyncGrid
