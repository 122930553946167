import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputProps,
  Skeleton,
} from '@mui/joy'
import ru from 'date-fns/locale/ru'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { InputMaskElement } from 'imask'
import { ChangeEvent, forwardRef } from 'react'
import React from 'react'
import DatePicker from 'react-datepicker'

import { useFormContext } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import { uiDateFormat } from '~/shared/config/datetime'
import { formatForApi } from '~/shared/lib/date'
import { CalendarIcon } from '~/shared/ui/Icons'
import { DatePickerPopper, Input } from '../ui/styled'
import 'react-datepicker/dist/react-datepicker.css'

export type DatePickerInputProps = {
  startName: string
  endName: string
  skeletonShow?: boolean
  label?: string
} & Omit<InputProps, 'name' | 'slotProps'>

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
}

const MaskAdapter = forwardRef<HTMLElement, CustomProps>(function Wrapper(
  { onChange, ...other },
  ref: InputMaskElement,
) {
  return (
    <IMaskInput
      {...other}
      mask='##.##.#### - ##.##.####'
      definitions={{
        '#': /[0-9]/,
      }}
      onInput={(value: ChangeEvent<HTMLInputElement>) => onChange(value)}
      inputRef={ref}
    />
  )
})

export function DateRangeInput({
  skeletonShow,
  label,
  placeholder,
  startName,
  endName,
  ...inputProps
}: DatePickerInputProps) {
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const startDate = getValues(startName) || null
  const endDate = getValues(endName) || null

  const startMessageError = errors[startName]?.message as string
  const endMessageError = errors[endName]?.message as string
  const isValidateStartError = Boolean(errors[startName])
  const isValidateEndError = Boolean(errors[endName])

  const handleDateChange = (
    dates: Date | Date[] | [Date | null, Date | null] | null,
  ) => {
    const [start, end] = dates as Date[]
    setValue(startName, start ? formatForApi(start as Date) : '')
    setValue(endName, end ? formatForApi(end as Date) : '')
  }

  return (
    <FormControl
      error={isValidateStartError || isValidateEndError}
      sx={{
        position: 'initial',
      }}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <DatePicker
        onChange={handleDateChange}
        startDate={startDate ? new Date(startDate) : null}
        endDate={endDate ? new Date(endDate) : null}
        selectsRange
        locale={ru}
        dateFormat={uiDateFormat}
        placeholderText={placeholder}
        popperContainer={DatePickerPopper}
        disabledKeyboardNavigation
        customInput={
          <Input
            readOnly={true}
            startDecorator={<CalendarIcon />}
            name={startName}
            {...inputProps}
            fullWidth
            size='lg'
            slotProps={{
              ...(skeletonShow ? { root: { component: Skeleton } } : {}),
              input: {
                component: MaskAdapter,
              },
            }}
          />
        }
      />
      {(isValidateStartError || isValidateEndError) && (
        <FormHelperText>
          {startMessageError || endMessageError || ''}
        </FormHelperText>
      )}
    </FormControl>
  )
}
